.main-loader {
	position: fixed;
	width: 100%;
	background: #ffffff85;
	text-align: center;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 9999;
}

.main-loader .loader {
	position: absolute;
	margin: 0px auto;
	width: 60px;
	height: 60px;
	color: #000;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	z-index: 112;
}
.loader-block {
	position: absolute;
	width: 100%;
	background: #ffffff85;
	text-align: center;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
}

.loader-block .loader {
	position: absolute;
	margin: 0px auto;
	width: 60px;
	height: 60px;
	color: #000;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	z-index: 112;
}

.circular-loader {
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	height: 100%;
	-webkit-transform-origin: center center;
	-ms-transform-origin: center center;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
}

.loader-path {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	-webkit-animation: dash 1.5s ease-in-out infinite,
		color 6s ease-in-out infinite;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
.table-loader {
	position: relative;
	height: 150px;
}

.email-loader .loader {
	width: 40px;
}

.edit-email-loader .loader {
	width: 20px;
}
.edit-email-loader .loader-block {
	background: none;
}
